
const getUrlApi = () =>{
  let protocol = window.location.protocol;

  if(window.location.hostname == '127.0.0.1' || window.location.hostname == 'localhost'){
    return `${protocol}//api.task-manager.localhost/api`;
  }
  
  let hostName = window.location.hostname.split('.');

  if(hostName.length == 2){
    let baseUrl = window.location.hostname;
    return `${protocol}//api.${baseUrl}/api`;
  }

  if(hostName.length == 3){
    let baseUrl = `${hostName[1]}.${hostName[2]}`;
    return `${protocol}//api.${baseUrl}/api`;
  }
}

const getUrlFiles = () =>{
  let protocol = window.location.protocol;

  if(window.location.hostname == '127.0.0.1' || window.location.hostname == 'localhost'){
    return `${protocol}//documents.task-manager.localhost`;
  }

  let hostName = window.location.hostname.split('.');

  if(hostName.length == 2){
    let baseUrl = window.location.hostname;
    return `${protocol}//documents.${baseUrl}`;
  }

  if(hostName.length == 3){
    let baseUrl = `${hostName[1]}.${hostName[2]}`;
    return `${protocol}//documents.${baseUrl}`;
  }
}

const getDomain = () =>{
  let domain = window.location.hostname.split('.');

  if(window.location.hostname == '127.0.0.1' || window.location.hostname == 'localhost'){
    return 'localhost';
  }

  if(domain.length == 2){
    return domain[0];
  }

  if(domain.length == 3){
    return domain[1];
  }
}

const getFilesUrl = () =>{
  let protocol = window.location.protocol;
  let domain = window.location.hostname;

  if(window.location.hostname == '127.0.0.1' || window.location.hostname == 'localhost'){
    return 'http://files.localhost';
  }

  return 'https://files.omnixsf.com';
}

const getUrlChat = () =>{
  let protocol = window.location.protocol;

  if(window.location.hostname == '127.0.0.1' || window.location.hostname == 'localhost'){
    return `http://localhost:4000`;
  }
  
  let hostName = window.location.hostname.split('.');

  if(hostName.length == 2){
    let baseUrl = window.location.hostname;
    return `${protocol}//chat.${baseUrl}`;
  }

  if(hostName.length == 3){
    let baseUrl = `${hostName[1]}.${hostName[2]}`;
    return `${protocol}//chat.${baseUrl}`;
  }
}

export const getEnvVariables = () => {
  return {
    API_URL: getUrlApi(),//import.meta.env.VITE_APP_API_URL,
    DOMAIN: getDomain(),
    URL_FILES: getUrlFiles(),
    FILES_URL: getFilesUrl(),
    VITE_APP_DEFAULT_LANG: import.meta.env.VITE_APP_DEFAULT_LANG,
    SOCKET_MAP_URL: import.meta.env.VITE_APP_SOCKET_MAP_URL,
    GOOGLE_MAPS_API_KEY: 'AIzaSyB3-ecY6hEUuvkSuzcVYo7CAlsMwZCpHwg',
    INTRANET: '/intranet',
    CHAT_BLANK_LIST: ['qarsagency', 'rsagency'],
    API_CHAT_URL: getUrlChat(),
    DOMAIN_SITES: ['localhost', 'gassapp', 'qagassapp'],
  }
}

