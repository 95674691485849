import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";


const langSelected = localStorage.getItem('i18nextLng');

i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  lng: langSelected,
  resources : {
    en: {
      translation: {
        
      }
    },
    es: {
      translation: {
        
      }
    }
  },
  debug: true,
  whitelist: ['en', 'es'],
  react: {
    useSuspense: false,
  },
});

export default i18next;