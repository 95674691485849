import React from 'react';
import { Link } from 'react-router-dom';
import { getEnvVariables } from '@/constants/getEnvVariables';

const { DOMAIN } = getEnvVariables();

const FooterMessage = () => {
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return 'Localhost';
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'rsagencyqa' || DOMAIN == 'rs-agency'){
    return 'RS Agency';
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'gassappqa'){
    return 'Gassapp';
  }
  if(DOMAIN == 'dataprocess'){
    return 'DataProcess';
  }
  if(DOMAIN == 'ipusa'){
    return 'IPUSA';
  }
  if(DOMAIN == 'ommixqa' || DOMAIN == 'omnixsf'){
    return 'OmnixSolutions';
  }
  if(DOMAIN == 'sungrow'){
    return 'Sungrow';
  }
  if(DOMAIN == 'witmakers'){
    return 'Witmakers';
  }
  if(DOMAIN == 'tcbdrones'){
    return 'TCB Drones';
  }
  if(DOMAIN == 'bravat'){
    return 'Bravat';
  }
}

const Footer = () => (
  <div className="">
    <div className="main-footer">
      <div className="container-fluid pd-t-0 ht-100p">
        <span> Copyright © 2023 <Link to="#" className="text-primary">{FooterMessage()}</Link>. Designed with <span className="fa fa-heart text-danger"></span> by <Link to="https://omnixsf.com/" target='_blank'> OmnixSolutions Inc.</Link> All rights reserved.</span>
      </div>
    </div>
  </div>
);

export default Footer;
