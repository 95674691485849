import React from 'react';
import { Imagesdata } from '@/constants/Images';
import { getEnvVariables } from '@/constants/getEnvVariables';

const { DOMAIN } = getEnvVariables();


export const showLogoAuth = () =>{
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return (<img src={Imagesdata("logoTcbDrones")} className="ht-xl-80p wd-md-70p wd-xl-60p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'qarsagency' || DOMAIN == 'rs-agency'){
    return (<img src={Imagesdata("FondoAuthRsAgency")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'qagassapp'){
    return (<img src={Imagesdata("LogoLoginGassapp")} className="ht-xl-60p wd-md-100p wd-xl-60p mx-auto mt-8 mb-5" alt="logo" />);
  }
  if(DOMAIN == 'omnixsf'){
    return (<img src={Imagesdata("FondoAuthOmnix")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'sungrow'){
    return (<img src={Imagesdata("FondoAuthSunGrow")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'witmakers'){
    return (<img src={Imagesdata("FondoAuthWitMakers")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'cecat'){
    return (<img src={Imagesdata("LogoCecat")} className="ht-xl-50p wd-md-60p wd-xl-50p mx-auto" alt="logo" />);
  }
  if(DOMAIN == 'infinytask'){
    return (<></>);
  }
  if(DOMAIN == 'orsr'){
    return (<img src={Imagesdata("logoBlackOrsr")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'gruponordeste'){
    return (<img src={Imagesdata("LogoGrupoNordeste")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'yourbestcleaning'){
    return (<img src={Imagesdata("logoCleaning")} className="ht-xl-80p wd-md-100p wd-xl-90p mx-auto my-10" alt="logo" />);
  }
  if(DOMAIN == 'tcbdrones'){
    return (<img src={Imagesdata("logoTcbDrones")} className="ht-xl-80p wd-md-70p wd-xl-60p mx-auto my-10" alt="logo" />);
  }
}

export const showBackgroundAuth = () => {
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return {}; 
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'qarsagency' || DOMAIN == 'rs-agency'){
    return {};
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'qagassapp'){
    return {backgroundImage: `url(${Imagesdata("FondoLoginGassapp")})`};
  }
  if(DOMAIN == 'omnixsf'){
    return {};
  }
  if(DOMAIN == 'sungrow'){
    return {};
  }
  if(DOMAIN == 'witmakers'){
    return {};
  }
  if(DOMAIN == 'cecat'){
    return {backgroundImage: `url(${Imagesdata("FondoAuthCecat")})`, backgroundSize: '60% 60%', backgroundPosition: 'bottom' , backgroundRepeat: 'no-repeat' }; 
  }
  if(DOMAIN == 'infinytask'){
    return {backgroundImage: `url(${Imagesdata("FondoAuthInfiny")})`, backgroundSize: '100% 100%', backgroundPosition: 'bottom' , backgroundRepeat: 'no-repeat' }; 
  }
  if(DOMAIN == 'orsr'){
    return {backgroundImage: `url(${Imagesdata("fondoOrsr")})`};
  }
  if(DOMAIN == 'gruponordeste'){
    return {}; 
  }
  if(DOMAIN == 'yourbestcleaning'){
    return {}; 
  }
  if(DOMAIN == 'tcbdrones'){
    return {}; 
  }
}

export const showLogoHeader = () =>{
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return (
      <>
        <img src={Imagesdata("logoCleaning2")} className="logo-1" alt="logo" />
        <img src={Imagesdata("logoCleaning2")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'qarsagency' || DOMAIN == 'rs-agency'){
    return (
      <>
        <img src={Imagesdata("LogoRsAgency")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoRsAgency")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'qagassapp'){
    return (
      <>
        <img src={Imagesdata("LogoGassapp")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoGassapp")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'omnixsf'){
    return (
      <>
        <img src={Imagesdata("LogoOmnix")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoOmnix")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'sungrow'){
    return (
      <>
        <img src={Imagesdata("LogoSunGrow")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoSunGrow")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'witmakers'){
    return (
      <>
        <img src={Imagesdata("LogoWitMakers")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoWitMakers")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'cecat'){
    return (
      <>
        <img src={Imagesdata("LogoCecat")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoCecat")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'infinytask'){
    return (
      <>
        <img src={Imagesdata("LogoInfiny")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoInfiny")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'orsr'){
    return (
      <>
        <img src={Imagesdata("logoBlackOrsr")} className="logo-1" alt="logo" />
        <img src={Imagesdata("logoWhiteOrsr")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'gruponordeste'){
    return (
      <>
        <img src={Imagesdata("LogoGrupoNordeste")} className="logo-1" alt="logo" />
        <img src={Imagesdata("LogoGrupoNordeste")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'yourbestcleaning'){
    return (
      <>
        <img src={Imagesdata("logoCleaning2")} className="logo-1" alt="logo" />
        <img src={Imagesdata("logoCleaning2")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
  if(DOMAIN == 'tcbdrones'){
    return (
      <>
        <img src={Imagesdata("logoTcbDrones")} className="logo-1" alt="logo" />
        <img src={Imagesdata("logoTcbDrones")} className="dark-logo-1" alt="logo" />
      </>
    )
  }
}

export const showLogoSidebar = () =>{
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return (<img src={Imagesdata("logoTcbDrones")} className="main-logo" alt="logo" style={{ height: '140%' }}/>)
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'qarsagency' || DOMAIN == 'rs-agency'){
    return (<img src={Imagesdata("LogoRsAgency")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'qagassapp'){
    return (<img src={Imagesdata("LogoGassapp")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'omnixsf'){
    return (<img src={Imagesdata("LogoOmnix")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'sungrow'){
    return (<img src={Imagesdata("LogoSunGrow")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'witmakers'){
    return (<img src={Imagesdata("LogoWitMakers")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'cecat'){
    return (<img src={Imagesdata("LogoCecat")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'infinytask'){
    return (<img src={Imagesdata("LogoMobileInfiny")} className="main-logo" alt="logo" style={{ height: '130%' }}/>)
  }
  if(DOMAIN == 'orsr'){
    return (<img src={Imagesdata("logoBlackOrsr")} className="main-logo" alt="logo" style={{ height: '130%' }}/>)
  }
  if(DOMAIN == 'gruponordeste'){
    return (<img src={Imagesdata("LogoGrupoNordeste")} className="main-logo" alt="logo" style={{ height: '130%' }}/>)
  }
  if(DOMAIN == 'yourbestcleaning'){
    return (<img src={Imagesdata("logoCleaning2")} className="main-logo" alt="logo" style={{ height: '130%' }}/>)
  }
  if(DOMAIN == 'tcbdrones'){
    return (<img src={Imagesdata("logoTcbDrones")} className="main-logo" alt="logo" style={{ height: '130%' }}/>)
  }
}

export const showLogoMobileSidebar = () =>{
  if(DOMAIN == 'localhost' || DOMAIN == '127.0.0.1'){
    return (<img src={Imagesdata("logoTcbDrones")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'rsagency' || DOMAIN == 'qarsagency' || DOMAIN == 'rs-agency'){
    return (<img src={Imagesdata("LogoMobileRsAgency")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'gassapp' || DOMAIN == 'qagassapp'){
    return (<img src={Imagesdata("LogoMobileGassapp")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'omnixsf'){
    return (<img src={Imagesdata("LogoOmnix")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'sungrow'){
    return (<img src={Imagesdata("LogoMobileSunGrow")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'witmakers'){
    return (<img src={Imagesdata("LogoMobileWitMakers")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'cecat'){
    return (<img src={Imagesdata("LogoMobileCecat")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'infinytask'){
    return (<img src={Imagesdata("LogoMobileInfiny")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'orsr'){
    return (<img src={Imagesdata("logoBlackOrsr")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'gruponordeste'){
    return (<img src={Imagesdata("LogoGrupoNordeste")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'yourbestcleaning'){
    return (<img src={Imagesdata("logoCleaning2")} className="main-logo" alt="logo"/>)
  }
  if(DOMAIN == 'tcbdrones'){
    return (<img src={Imagesdata("logoTcbDrones")} className="main-logo" alt="logo"/>)
  }
}