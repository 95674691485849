import { createContext, useContext, useEffect } from "react";
import { getGlobal, useGlobal, setGlobal } from 'reactn';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import * as _ from "lodash";
import { useLocation, useNavigate } from 'react-router-dom';

import { useChatStore } from '@/_zustand/chatStore';
import { useSessionStore } from '@/_zustand/sessionStore';
import { fetchUsers } from "@/api";
import setAuthToken from '@/redux/actions/setAuthToken';
import initIO from '@/redux/actions/initIO';
import { getEnvVariables } from '@/constants/getEnvVariables';
import sendActivity from '@/redux/actions/sendActivity';

const ChatContext = createContext("chat");

export const ChatProvider = ({ children }) => {
  const dispatch = useDispatch();
  const io = useSelector((state) => state.io.io);
  const callIncrement = useSelector((state) => state.rtc.callIncrement);
  const callData = useSelector((state) => state.rtc.callData);
  const token = useGlobal('token')[0];
  const setStartingPoint = useGlobal('entryPath')[1];
  const location = useLocation();
  const { INTRANET } = getEnvVariables();
  const [over, setOver] = useGlobal('over');
  const navigate = useNavigate();
  const { setUsersOnline, setUsersOffline, setRoom } = useChatStore();
  const { user } = useSessionStore();

  useEffect(() => {
    if (!callData) return;
    setGlobal({
      audio: true,
      video: false,
      callDirection: 'incoming',
      meeting: { _id: callData.meetingID },
    }).then(() => {
      navigate(`${INTRANET}/chat/meeting/${callData.meetingID}`, { replace: true });
    });
  }, [callIncrement, callData]);

  useEffect(() => {
    if (location.pathname !== `${INTRANET}/chat/`) setOver(true);
  }, [location]);

  useEffect(() => {
    //console.log("ChatProvider IO ",io);

    if (!io || !getGlobal().user || !token) return;

    /* socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      console.error(API_CHAT_URL);
    }); */

    let focusCount = 0;
    const interval = setInterval(() => {
      if (!document.hasFocus()) {
        focusCount++;
        if (focusCount === 10) {
          io.emit('status', { status: 'away' });
        }
      } else if (focusCount !== 0) {
        focusCount = 0;
        io.emit('status', { status: 'online' });
      }
    }, 1000 * 60 * 5);

    io.off('sendActivity');
    io.on('sendActivity', () => {
      console.log('sendActivity');
      onSendActivity();
    });
    
    return () => {
      io.off('sendActivity');
      clearInterval(interval);
    };

  }, [io, token]);

  useEffect(() => {
    return () => {
      try {
        if (getGlobal().audioStream) {
          getGlobal()
            .audioStream.getTracks()
            .forEach((track) => track.stop());
        }
      } catch (e) {}
      try {
        if (getGlobal().videoStream) {
          getGlobal()
            .videoStream.getTracks()
            .forEach((track) => track.stop());
        }
      } catch (e) {}
    };
  }, []);

  if (!window.loaded) {
    setStartingPoint(window.location.pathname);
    const splitPath = window.location.pathname.split('/');
    const route = splitPath[1];
    const token = splitPath[2];
    if (route === 'auth' && token && token.length > 20) {
      let decoded;
      try {
        decoded = jwtDecode(token);
        if (typeof decoded !== 'object' || typeof decoded.id !== 'string') return;
        setAuthToken(token);
        localStorage.setItem('token-chat', token);
        localStorage.setItem('user-chat', JSON.stringify(decoded));
        setGlobal({
          user: decoded,
          token,
        }).then(() => {
          dispatch(initIO(token));
        });
      } catch (e) {
        console.error("ERROR EN !window.loaded ",e);
        
      }
    }
    window.loaded = true;
  }

  const onSendActivity = async () => {
    //console.log("onSendActivity");
    const usersResp = await fetchUsers();
    let users = [];

    if(_.size(usersResp) > 0) {
      users = usersResp.map((user) => {
        return {
          id: user.id,
          name: (user.person_id) ? user.person.pop_nombre : user.email,
          email: user.email,
          position: (user.person_id && user.person.position_id) ? user.person.position.name : '',
          role: user.role.rol_descri,
          activity: user.activity,
          last_activity: user.last_activity,
        };
      });
    }

    let usersOnline = _.filter(users, { 'activity': 'Online' });
    let usersOffline = _.filter(users, { 'activity': 'Offline' });

    setUsersOnline(usersOnline);
    setUsersOffline(usersOffline);
  }

  const joinRoom = (room) => {
    //console.log('joinRoom',room);
    if(!io) return;
    setRoom(io.id, room);
    sendActivity(user.id, 'Online');
  };

  const leaveRoom = (room) => {
    //console.log('leaveRoom',room);
    setRoom();
    sendActivity(user.id, 'Offline');

  };

  const bussyRoom = (room) => {
    //console.log('bussyRoom',room);
    if(!io) return;
    setRoom(io.id, room);
    sendActivity(user.id, 'Bussy');
  };

  const onlineRoom = (room) => {
    //console.log('onlineRoom',room);
    if(!io) return;
    setRoom(io.id, room);
    sendActivity(user.id, 'Online');
  };

  return (
    <ChatContext.Provider
      value={{
        joinRoom,
        leaveRoom,
        bussyRoom,
        onlineRoom,
      }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(ChatContext);
};